import type { FilterStatesKey } from '~/modules/search/type/filter-state';

export const COMPANY_IMAGE_CROP = {
    portrait: {
        width: 590,
        height: 884,
    },
    landscape: {
        width: 1205,
        height: 803,
    },
};

export const COMPANY_VIDEO_CROP = {
    desktop: {
        width: 449,
        height: 244,
    },
    mobile: {
        width: 414,
        height: 264,
    },
};

export const IN_PAGE_SEARCH_PAGE_SIZE = 36;
export const IN_PAGE_SEARCH_PARAM_KEY = 'q';
export const IN_PAGE_SEARCH_SORT_BY = 'sortBy';
export const IN_PAGE_SEARCH_ENABLED_SHOP = 'shop';
export const IN_PAGE_SEARCH_IGNORE_FILTER_KEYS: FilterStatesKey[] = [
    'professionals',
    'professionalParents',
    'professionalCategories',
];

export const ABOUT_BANNER_SIZE = 880;
export const ABOUT_BANNER_SIZE_MOBILE = 378;

export const INFO_PAGE_MATCH_ID = 'routes/_app.professional.$urlSegment.info';
export const INFO_PAGE_MATCH_ID_EDITMODE =
    'routes/_app.professional-preview.$urlSegment.info';

export const PAGE_ANCHORS = {
    TEAM_MEMBERS: 'team-members-section',
    TESTIMONIALS: 'testimonials-section',
};

export const BANNER_CAROUSEL_DELAY = 4000;
export const BANNER_CAROUSEL_UPDATE_RATE = 500;
